import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import {isUserAuthenticated, getLoggedInUser, isWorkerDisabledPath} from '../helpers/authUtils';
import CompanyContractChild from "../pages/app/company/CompanyContractChild";

// lazy load all the views

// sso
const LoginSso = React.lazy(() => import('../pages/auth/LoginSso'));

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const BalanceRequest = React.lazy(() => import('../pages/auth/BalanceRequest'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Reset = React.lazy(() => import('../pages/auth/Reset'));
const SupplierReset = React.lazy(() => import('../pages/auth/SupplierReset'));
const WorkerReset = React.lazy(() => import('../pages/auth/WorkerReset'));
const OrganizationReset = React.lazy(() => import('../pages/auth/OrganizationReset'));

// register
const RegisterWizard = React.lazy(() => import('../pages/app/user/RegisterWizard'));
const RegisterWelcome = React.lazy(() => import('../pages/app/user/RegisterWelcome'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/app/dashboard/Dashboard'));
const Historic = React.lazy(() => import('../pages/app/dashboard/Historic'));
const AdminConfig = React.lazy(() => import('../pages/app/dashboard/AdminConfig'));
const AdminRecharge = React.lazy(() => import('../pages/app/dashboard/AdminRecharge'));
const AdminLogs = React.lazy(() => import('../pages/app/dashboard/AdminLogs'));

// user
const UserList = React.lazy(() => import('../pages/app/user/UserList'));
const UserManage = React.lazy(() => import('../pages/app/user/UserManage'));
const UserPassword = React.lazy(() => import('../pages/app/user/UserPassword'));

// supplier
const SupplierInvoices = React.lazy(() => import('../pages/app/suppliers/SupplierInvoices'));
const SupplierProfile = React.lazy(() => import('../pages/app/suppliers/SupplierProfile'));
const SupplierWizard = React.lazy(() => import('../pages/app/suppliers/SupplierWizard'));
const SupplierContract = React.lazy(() => import('../pages/app/suppliers/SupplierContract'));
const SupplierWelcome = React.lazy(() => import('../pages/app/suppliers/SupplierWelcome'));

// agency
const AgencyProfile = React.lazy(() => import('../pages/app/agency/AgencyProfile'));
const AgencyInvoices = React.lazy(() => import('../pages/app/agency/AgencyInvoices'));
const AgencyPayrolls = React.lazy(() => import('../pages/app/agency/AgencyPayrolls'));

// admin modules
const AdminSuppliers = React.lazy(() => import('../pages/app/admin/suppliers/AdminSuppliers'));

const UserSelections = React.lazy(() => import('../pages/app/user/UserSelections'));

const CompanyList = React.lazy(() => import('../pages/app/admin/companies/CompanyList'));
const CompanyManage = React.lazy(() => import('../pages/app/admin/companies/CompanyManage'));
const CompanySettings = React.lazy(() => import('../pages/app/admin/companies/CompanySettings'));

const KindergartenList = React.lazy(() => import('../pages/app/admin/suppliers/kindergartens/KindergartenList'));
const KindergartenSettings = React.lazy(() => import('../pages/app/admin/suppliers/kindergartens/KindergartenSettings'));
const KindergartenCharges = React.lazy(() => import('../pages/app/admin/suppliers/kindergartens/KindergartenCharges'));
const KindergartenPayments = React.lazy(() => import('../pages/app/admin/suppliers/kindergartens/KindergartenPayments'));
const KindergartenConciliation = React.lazy(() => import('../pages/app/admin/suppliers/kindergartens/KindergartenConciliation'));
const KindergartenRequests = React.lazy(() => import('../pages/app/admin/suppliers/kindergartens/KindergartenRequests'));
const KindergartenManage = React.lazy(() => import('../pages/app/admin/suppliers/kindergartens/KindergartenManage'));

const TrainingCenterList = React.lazy(() => import('../pages/app/admin/suppliers/trainingCenters/TrainingCenterList'));
const TrainingCenterSettings = React.lazy(() => import('../pages/app/admin/suppliers/trainingCenters/TrainingCenterSettings'));
const TrainingCenterCharges = React.lazy(() => import('../pages/app/admin/suppliers/trainingCenters/TrainingCenterCharges'));
const TrainingCenterPayments = React.lazy(() => import('../pages/app/admin/suppliers/trainingCenters/TrainingCenterPayments'));
const TrainingCenterConciliation = React.lazy(() => import('../pages/app/admin/suppliers/trainingCenters/TrainingCenterConciliation'));
const TrainingCenterRequests = React.lazy(() => import('../pages/app/admin/suppliers/trainingCenters/TrainingCenterRequests'));
const TrainingCenterManage = React.lazy(() => import('../pages/app/admin/suppliers/trainingCenters/TrainingCenterManage'));

const InsuranceList = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsuranceList'));
const InsurancePlanList = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsurancePlanList'));
const InsuranceCoverageList = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsuranceCoverageList'));
const InsurancePriceList = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsurancePriceList'));
const InsurancePolicies = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsurancePolicies'));
const InsurancePoliciesManage = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsurancePoliciesManage'));
const InsuranceSettings = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsuranceSettings'));
const InsuranceCharges = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsuranceCharges'));
const InsurancePayments = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsurancePayments'));
const InsuranceConciliation = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsuranceConciliation'));
const InsuranceManage = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsuranceManage'));
const InsurancePlanManage = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsurancePlanManage'));
const InsuranceCoverageManage = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsuranceCoverageManage'));
const InsurancePriceManage = React.lazy(() => import('../pages/app/admin/suppliers/insurances/InsurancePriceManage'));
const InsuranceInfo = React.lazy(() => import('../pages/app/benefit/insurance/InsuranceInfo'));

const CardSummary = React.lazy(() => import('../pages/app/admin/suppliers/cards/CardSummary'));
const CardRequests = React.lazy(() => import('../pages/app/admin/suppliers/cards/CardRequests'));
const CardCharges = React.lazy(() => import('../pages/app/admin/suppliers/cards/CardCharges'));
const CardPayments = React.lazy(() => import('../pages/app/admin/suppliers/cards/CardPayments'));
const CardConciliation = React.lazy(() => import('../pages/app/admin/suppliers/cards/CardConciliation'));
const CardPending = React.lazy(() => import('../pages/app/admin/suppliers/cards/CardPending'));

const InvoicingSummary = React.lazy(() => import('../pages/app/admin/invoicing/InvoicingSummary'));
const DocumentsSummary = React.lazy(() => import('../pages/app/admin/documents/DocumentsSummary'));

// Benefit
const Benefit = React.lazy(() => import('../pages/app/benefit/Benefit'));
const RestaurantBenefit = React.lazy(() => import('../pages/app/benefit/restaurant/RestaurantBenefit'));
const TransportBenefit = React.lazy(() => import('../pages/app/benefit/transport/TransportBenefit'));
const KindergartenBenefit = React.lazy(() => import('../pages/app/benefit/kindergarten/KindergartenBenefit'));
const InsuranceBenefit = React.lazy(() => import('../pages/app/benefit/insurance/InsuranceBenefit'));
const TrainingBenefit = React.lazy(() => import('../pages/app/benefit/trainingCenter/TrainingBenefit'));

// Demand
const Demand = React.lazy(() => import('../pages/app/demand/Demand'));
const KindergartenDemand = React.lazy(() => import('../pages/app/demand/kindergarten/KindergartenDemand'));
const InsuranceDemand = React.lazy(() => import('../pages/app/demand/insurance/InsuranceDemand'));
const TrainingDemand = React.lazy(() => import('../pages/app/demand/training/TrainingDemand'));
const TrainingDemandManage = React.lazy(() => import('../pages/app/demand/training/TrainingDemandManage'));

// Payroll
const Payroll = React.lazy(() => import('../pages/app/payroll/Payroll'));

// Invoice
const Invoice = React.lazy(() => import('../pages/app/invoice/Invoice'));

// Config
const UserConfig = React.lazy(() => import('../pages/app/config/UserConfig'));

// Workers
const Simulate = React.lazy(() => import('../pages/app/simulate/Simulate'));
const ContractRequest = React.lazy(() => import('../pages/app/savings/ContractRequest'));
const ContractRemind = React.lazy(() => import('../pages/app/savings/ContractRemind'));
const Sign = React.lazy(() => import('../pages/app/savings/Sign'));
const Savings = React.lazy(() => import('../pages/app/savings/Savings'));
const SavingsRestaurant = React.lazy(() => import('../pages/app/savings/SavingsRestaurant'));
const SavingsTransport = React.lazy(() => import('../pages/app/savings/SavingsTransport'));
const SavingsKindergarten = React.lazy(() => import('../pages/app/savings/SavingsKindergarten'));
const SavingsKindergartenLegacy = React.lazy(() => import('../pages/app/savings/legacy/SavingsKindergarten'));
const SavingsTraining = React.lazy(() => import('../pages/app/savings/SavingsTraining'));
const SavingsTrainingLegacy = React.lazy(() => import('../pages/app/savings/SavingsTrainingLegacy'));
const SavingsTrainingSent = React.lazy(() => import('../pages/app/savings/SavingsTrainingSent'));
const SavingsInsurance = React.lazy(() => import('../pages/app/savings/SavingsInsurance'));
const SavingsInsuranceSent = React.lazy(() => import('../pages/app/savings/SavingsInsuranceSent'));
const Selections = React.lazy(() => import('../pages/app/selections/Selections'));
const Cards = React.lazy(() => import('../pages/app/cards/Cards'));
const SelectionsKindergarten = React.lazy(() => import('../pages/app/selections/SelectionsKindergarten'));
const SelectionsTraining = React.lazy(() => import('../pages/app/selections/SelectionsTraining'));
const SelectionsInsurance = React.lazy(() => import('../pages/app/selections/SelectionsInsurance'));
const UserProfile = React.lazy(() => import('../pages/app/user/UserProfile'));
const UserBeneficiaries = React.lazy(() => import('../pages/app/user/UserBeneficiaries'));
const UserDocuments = React.lazy(() => import('../pages/app/user/UserDocuments'));

// Organizations
const CompanyTheme = React.lazy(() => import('../pages/app/company/CompanyTheme'));
const CompanySso = React.lazy(() => import('../pages/app/company/CompanySso'));
const CompanyProfile = React.lazy(() => import('../pages/app/company/CompanyProfile'));
const CompanyConfig = React.lazy(() => import('../pages/app/company/CompanyConfig'));
const CompanyPayrolls = React.lazy(() => import('../pages/app/company/CompanyPayrolls'));
const CompanyDocuments = React.lazy(() => import('../pages/app/company/CompanyDocuments'));
const CompanyContract = React.lazy(() => import('../pages/app/company/CompanyContract'));
const CompanyWelcome = React.lazy(() => import('../pages/app/company/CompanyWelcome'));

// normative
const NormativeList = React.lazy(() => import('../pages/app/normative/NormativeList'));
const NormativeManage = React.lazy(() => import('../pages/app/normative/NormativeManage'));

// FAQ
const FaqList = React.lazy(() => import('../pages/app/faq/FaqList'));
const FaqManage = React.lazy(() => import('../pages/app/faq/FaqManage'));

// modality
const ModalityList = React.lazy(() => import('../pages/app/modality/ModalityList'));
const ModalityManage = React.lazy(() => import('../pages/app/modality/ModalityManage'));

// auxiliary
const ModalityCategoryList = React.lazy(() => import('../pages/app/auxiliary/modality/ModalityCategoryList'));
const ModalityCategoryManage = React.lazy(() => import('../pages/app/auxiliary/modality/ModalityCategoryManage'));
const DocumentCategoryList = React.lazy(() => import('../pages/app/auxiliary/document/DocumentCategoryList'));
const DocumentCategoryManage = React.lazy(() => import('../pages/app/auxiliary/document/DocumentCategoryManage'));
const FaqCategoryList = React.lazy(() => import('../pages/app/auxiliary/faq/FaqCategoryList'));
const FaqCategoryManage = React.lazy(() => import('../pages/app/auxiliary/faq/FaqCategoryManage'));

// ess admin
const EssProviderList = React.lazy(() => import('../pages/app/admin/ess/ProviderList'));
const EssProviderManage = React.lazy(() => import('../pages/app/admin/ess/ProviderManage'));
const EssActivityList = React.lazy(() => import('../pages/app/admin/ess/ActivityList'));
const EssActivityManage = React.lazy(() => import('../pages/app/admin/ess/ActivityManage'));
const EssPriceList = React.lazy(() => import('../pages/app/admin/ess/PriceList'));
const EssPriceManage = React.lazy(() => import('../pages/app/admin/ess/PriceManage'));

// ess
const EssServices = React.lazy(() => import('../pages/app/ess/EssServices'));
const EssList = React.lazy(() => import('../pages/app/ess/EssList'));
const EssActivity = React.lazy(() => import('../pages/app/ess/EssActivity'));
const EssPrice = React.lazy(() => import('../pages/app/ess/EssPrice'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, exceptions, ...rest }) => (
    <Route
        {...rest}
        render={props => {

            let loggedInUser = getLoggedInUser();
            const isAuthTokenValid = isUserAuthenticated();

            if (!isAuthTokenValid) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                if (!exceptions || !(exceptions.filter(element => loggedInUser.exceptions.includes(element))).length) {
                    // role not authorised so redirect to home page
                    return <Redirect to={{pathname: '/'}}/>;
                }
            }

            // Inactive Worker
            if (loggedInUser.role === 'WorkerDisabled') {
                if (!isWorkerDisabledPath(props.location.pathname)) {
                    return <Redirect to={{pathname: '/dashboard'}}/>;
                }
            }

            // Organization User without contract
            if (loggedInUser.role === 'User') {

                if (loggedInUser.contract.isContractSigned) {

                    if (!loggedInUser.contract.isPecunpayAdded) {
                        return <CompanyProfile {...props} pecunpayReady={true} />
                    }

                } else {

                    if (loggedInUser.parent) {
                        // No contract, child company
                        return <CompanyContractChild/>
                    }

                    if (loggedInUser.contract.isContractReady) {
                        // No contract, has all necessary data
                        return <CompanyContract {...props} />
                    } else {
                        // No contract, has not all necessary data
                        return <CompanyProfile {...props} contractReady={true}/>
                    }
                }
            }

            // Worker without contract
            if (loggedInUser.role === 'Worker' && !loggedInUser.hasContract &&
                props.location.pathname !== "/worker/register" &&
                props.location.pathname !== "/worker/register/welcome") {
                return <Redirect push to="/worker/register" />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

const dashboardRoutes = {
    path: '/dashboard',
    exact: true,
    name: 'menu.dashboard.main',
    icon: 't-icon t-icon-home',
    header: 'menu.labels.navigation',
    component: Dashboard,
    roles: ['Admin','User','Worker', 'WorkerDisabled', 'Supplier', 'Agency'],
    route: PrivateRoute
};

const companyRoutes = {
    path: '/company/list',
    name: 'menu.company.main',
    icon: 't-icon t-icon-organization',
    component: CompanyList,
    exact: true,
    route: PrivateRoute,
    roles: ['Admin']
};

const userRoutes = {
    path: '/user/list',
    name: 'menu.user.main',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 't-icon t-icon-worker',
    component: UserList,
};

const workerRoutes = {
    path: '/worker/list',
    name: 'menu.worker.main',
    route: PrivateRoute,
    roles: ['User'],
    icon: 't-icon t-icon-worker',
    component: UserList,
};

const supplierRoutes = {
    path: '/suppliers',
    name: 'menu.supplier.main',
    icon: 't-icon t-icon-supplier',
    component: AdminSuppliers,
    exact: true,
    route: PrivateRoute,
    roles: ['Admin']
};

const documentRoutes = {
    path: '/admin/documents',
    name: 'menu.documents.main',
    icon: 't-icon t-icon-document',
    component: DocumentsSummary,
    route: PrivateRoute,
    roles: ['Admin']
};

const invoicingRoutes = {
    path: '/admin/invoicing',
    name: 'menu.invoicing.main',
    icon: 't-icon t-icon-invoice',
    component: InvoicingSummary,
    route: PrivateRoute,
    roles: ['Admin']
};

const benefitRoutes = {
    path: '/benefit',
    name: 'menu.benefit.main',
    exact: true,
    route: PrivateRoute,
    roles: ['User'],
    icon: 't-icon t-icon-service',
    component: Benefit,
};

const demandRoutes = {
    path: '/demand',
    name: 'menu.demand.main',
    exact: true,
    route: PrivateRoute,
    roles: ['Worker','User','Admin'],
    exceptions: ['trainingUser'],
    icon: 't-icon t-icon-demand',
    component: Demand,
};

const payrollRoutes = {
    path: '/payroll',
    name: 'menu.payroll.main',
    exact: true,
    route: PrivateRoute,
    roles: ['User'],
    icon: 't-icon t-icon-payroll',
    component: Payroll,
    isPayroll: true
};

const invoiceRoutes = {
    path: '/invoice',
    name: 'menu.invoice.main',
    exact: true,
    route: PrivateRoute,
    roles: ['User'],
    icon: 't-icon t-icon-invoice',
    component: Invoice,
    isInvoice: true
};

const supplierInvoiceRoutes = {
    path: '/supplier/invoice',
    name: 'menu.invoice.main',
    exact: true,
    route: PrivateRoute,
    roles: ['Supplier'],
    icon: 't-icon t-icon-invoice',
    component: SupplierInvoices,
};

const supplierProfileRoutes = {
    path: '/supplier/profile',
    name: 'menu.supplier.settings.profile',
    exact: true,
    route: PrivateRoute,
    roles: ['Supplier'],
    icon: 't-icon t-icon-data',
    component: SupplierProfile,
};

const agencyProfileRoutes = {
    path: '/agency/profile',
    name: 'app.agency.settings.profile',
    exact: true,
    route: PrivateRoute,
    roles: ['Agency'],
    icon: 't-icon t-icon-data',
    component: AgencyProfile,
};

const agencyInvoicesRoutes = {
    path: '/agency/invoices',
    name: 'app.agency.invoices.menu',
    exact: true,
    route: PrivateRoute,
    roles: ['Agency'],
    icon: 't-icon t-icon-invoice',
    component: AgencyInvoices,
};

const agencyPayrollsRoutes = {
    path: '/agency/payrolls',
    name: 'app.agency.payrolls.menu',
    exact: true,
    route: PrivateRoute,
    roles: ['Agency','Admin'],
    icon: 't-icon t-icon-payroll',
    component: AgencyPayrolls,
};

const userConfigRoutes = {
    path: '/config',
    name: 'menu.config.main',
    exact: true,
    route: PrivateRoute,
    roles: ['User'],
    icon: 't-icon t-icon-settings',
    component: UserConfig,
};

const simulateRoutes = {
    path: '/simulate',
    name: 'menu.simulate.main',
    exact: true,
    route: PrivateRoute,
    roles: ['Worker'],
    icon: 't-icon t-icon-simulate',
    component: Simulate,
};

const savingsRoutes = {
    path: '/savings',
    name: 'menu.savings.main',
    exact: true,
    route: PrivateRoute,
    roles: ['Worker'],
    icon: 't-icon t-icon-service',
    component: Savings,
};

const selectionsRoutes = {
    path: '/selections',
    name: 'menu.selections.main',
    exact: true,
    route: PrivateRoute,
    roles: ['Worker'],
    icon: 't-icon t-icon-selection',
    component: Selections,
};

const cardsRoutes = {
    path: '/cards',
    name: 'menu.cards.main',
    exact: true,
    route: PrivateRoute,
    roles: ['Worker', 'WorkerDisabled'],
    icon: 't-icon t-icon-pay',
    component: Cards,
};

const authUnexposedRoutes = {
    path: '/unexposed',
    name: 'unexposed',
    children: [
        {
            path: '/dashboard/historic',
            name: 'menu.dashboard.historic',
            exact: true,
            component: Historic,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/dashboard/admin/config',
            name: 'menu.dashboard.config',
            exact: true,
            component: AdminConfig,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/dashboard/admin/recharge',
            name: 'menu.dashboard.recharge',
            exact: true,
            component: AdminRecharge,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/dashboard/admin/logs',
            name: 'menu.dashboard.logs',
            exact: true,
            component: AdminLogs,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/benefit/restaurant/:id',
            name: 'menu.modality.restaurant.name',
            component: RestaurantBenefit,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/benefit/transport/:id',
            name: 'menu.modality.transport.name',
            component: TransportBenefit,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/benefit/kindergarten/:id',
            name: 'menu.modality.kindergarten.name',
            component: KindergartenBenefit,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/benefit/insurance/:id',
            name: 'menu.modality.insurance.name',
            component: InsuranceBenefit,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/benefit/trainingCenter/:id',
            name: 'menu.modality.trainingCenter.name',
            component: TrainingBenefit,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/demand/training/manage/:id',
            name: 'menu.demand.training',
            component: TrainingDemandManage,
            route: PrivateRoute,
            roles: ['Worker','User','Admin'],
            exceptions: ['trainingUser']
        },
        {
            path: '/demand/training',
            name: 'menu.demand.training',
            component: TrainingDemand,
            route: PrivateRoute,
            roles: ['Worker','User','Admin'],
            exceptions: ['trainingUser']
        },
        {
            path: '/demand/insurance',
            name: 'menu.demand.insurance',
            component: InsuranceDemand,
            route: PrivateRoute,
            roles: ['Worker','User','Admin']
        },
        {
            path: '/demand/kindergarten',
            name: 'menu.demand.kindergarten',
            component: KindergartenDemand,
            route: PrivateRoute,
            roles: ['Worker','User','Admin']
        },
        {
            path: '/company/add',
            name: 'menu.company.add',
            component: CompanyManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/company/edit/:id',
            name: 'menu.company.edit',
            component: CompanyManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/modality/list',
            name: 'menu.modality.main',
            component: ModalityList,
            route: PrivateRoute
        },
        {
            path: '/suppliers/kindergarten/list',
            exact: true,
            name: 'menu.modality.kindergarten.main',
            component: KindergartenList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/kindergarten/charges',
            exact: true,
            name: 'menu.modality.kindergarten.main',
            component: KindergartenCharges,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/kindergarten/conciliation',
            exact: true,
            name: 'menu.modality.kindergarten.main',
            component: KindergartenConciliation,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/kindergarten/payments',
            exact: true,
            name: 'menu.modality.kindergarten.main',
            component: KindergartenPayments,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/kindergarten/requests',
            exact: true,
            name: 'menu.modality.kindergarten.main',
            component: KindergartenRequests,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/trainingCenter/list',
            exact: true,
            name: 'menu.modality.trainingCenter.main',
            component: TrainingCenterList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/trainingCenter/charges',
            exact: true,
            name: 'menu.modality.trainingCenter.main',
            component: TrainingCenterCharges,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/trainingCenter/conciliation',
            exact: true,
            name: 'menu.modality.trainingCenter.main',
            component: TrainingCenterConciliation,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/trainingCenter/payments',
            exact: true,
            name: 'menu.modality.trainingCenter.main',
            component: TrainingCenterPayments,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/trainingCenter/requests',
            exact: true,
            name: 'menu.modality.trainingCenter.main',
            component: TrainingCenterRequests,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/list',
            exact: true,
            name: 'menu.modality.insurance.main',
            component: InsuranceList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/plan/list/:id',
            name: 'menu.modality.insurance.main',
            component: InsurancePlanList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/plan/list',
            name: 'menu.modality.insurance.main',
            component: InsurancePlanList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/coverage/list/:id',
            name: 'menu.modality.insurance.main',
            component: InsuranceCoverageList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/coverage/list',
            name: 'menu.modality.insurance.main',
            component: InsuranceCoverageList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/coverage/price/:id',
            name: 'menu.modality.insurance.main',
            component: InsurancePriceList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/price/list',
            name: 'menu.modality.insurance.main',
            component: InsurancePriceList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/policies',
            exact: true,
            name: 'menu.modality.insurance.main',
            component: InsurancePolicies,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/policies/manage/:id',
            exact: true,
            name: 'menu.modality.insurance.main',
            component: InsurancePoliciesManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/charges',
            exact: true,
            name: 'menu.modality.insurance.main',
            component: InsuranceCharges,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/conciliation',
            exact: true,
            name: 'menu.modality.insurance.main',
            component: InsuranceConciliation,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/payments',
            exact: true,
            name: 'menu.modality.insurance.main',
            component: InsurancePayments,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/card/summary',
            exact: true,
            name: 'menu.modality.card.summary',
            component: CardSummary,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/card/requests',
            exact: true,
            name: 'menu.modality.card.requests',
            component: CardRequests,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/card/charges',
            exact: true,
            name: 'menu.modality.card.charges',
            component: CardCharges,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/card/payments',
            exact: true,
            name: 'menu.modality.card.payments',
            component: CardPayments,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/card/conciliation',
            exact: true,
            name: 'menu.modality.card.conciliation',
            component: CardConciliation,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/card/pending',
            exact: true,
            name: 'menu.modality.card.pending',
            component: CardPending,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/supplier/wizard',
            exact: true,
            name: 'menu.supplier.wizard',
            component: SupplierWizard,
            route: PrivateRoute,
            roles: ['Supplier']
        },
        {
            path: '/supplier/contract',
            exact: true,
            name: 'menu.supplier.contract',
            component: SupplierContract,
            route: PrivateRoute,
            roles: ['Supplier']
        },
        {
            path: '/supplier/welcome',
            exact: true,
            name: 'menu.supplier.welcome',
            component: SupplierWelcome,
            route: PrivateRoute,
            roles: ['Supplier'],
            layout: 'AuthLayout'
        },
        {
            path: '/company/settings/:id',
            name: 'menu.company.settings.settings',
            component: CompanySettings,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/company/policies/:id',
            name: 'menu.company.settings.policies',
            component: InsuranceDemand,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/company/theme',
            name: 'menu.company.settings.theme',
            component: CompanyTheme,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/company/sso',
            name: 'menu.company.settings.sso',
            component: CompanySso,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/company/profile',
            name: 'menu.company.settings.profile',
            component: CompanyProfile,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/company/contract',
            exact: true,
            name: 'menu.company.contract',
            component: CompanyContract,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/company/welcome',
            exact: true,
            name: 'menu.company.welcome',
            component: CompanyWelcome,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/company/config',
            name: 'menu.company.settings.config',
            component: CompanyConfig,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/company/payrolls',
            name: 'menu.company.settings.payrolls',
            component: CompanyPayrolls,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/company/documents',
            name: 'menu.company.settings.documents',
            component: CompanyDocuments,
            route: PrivateRoute,
            roles: ['User']
        },
        {
            path: '/savings/contract/request',
            name: 'menu.savings.contract',
            exact: true,
            component: ContractRequest,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/contract/remind/:contract',
            name: 'menu.savings.contract',
            exact: true,
            component: ContractRemind,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/sign',
            name: 'menu.savings.sign',
            exact: true,
            component: Sign,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/restaurant',
            name: 'menu.savings.restaurant',
            component: SavingsRestaurant,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/transport',
            name: 'menu.savings.transport',
            component: SavingsTransport,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/kindergarten/:id',
            name: 'menu.savings.kindergarten',
            component: process.env.REACT_APP_LEGACY_KINDERGARTEN === 'on' ? SavingsKindergartenLegacy : SavingsKindergarten,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/kindergarten',
            name: 'menu.savings.kindergarten',
            component: process.env.REACT_APP_LEGACY_KINDERGARTEN === 'on' ? SavingsKindergartenLegacy : SavingsKindergarten,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/trainingCenter',
            exact: true,
            name: 'menu.savings.trainingCenter',
            component: process.env.REACT_APP_LEGACY_TRAINING === 'on' ? SavingsTrainingLegacy : SavingsTraining,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/training/sent',
            exact: true,
            name: 'menu.savings.training',
            component: SavingsTrainingSent,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/insurance/sent',
            exact: true,
            name: 'menu.savings.insurance',
            component: SavingsInsuranceSent,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/savings/insurance/:id',
            name: 'menu.savings.insurance',
            component: InsuranceInfo,
            route: PrivateRoute,
            roles: ['Worker','User']
        },
        {
            path: '/savings/insurance',
            name: 'menu.savings.insurance',
            component: SavingsInsurance,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/selections/restaurant',
            name: 'menu.selections.restaurant',
            exact: true,
            component: Cards,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/selections/transport',
            name: 'menu.selections.transport',
            exact: true,
            component: Cards,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/selections/kindergarten',
            name: 'menu.selections.kindergarten',
            exact: true,
            component: SelectionsKindergarten,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/selections/trainingCenter',
            name: 'menu.selections.trainingCenter',
            exact: true,
            component: SelectionsTraining,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/selections/insurance',
            name: 'menu.selections.insurance',
            exact: true,
            component: SelectionsInsurance,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/user/profile',
            name: 'menu.user.settings.profile',
            exact: true,
            component: UserProfile,
            route: PrivateRoute,
            roles: ['User','Worker','WorkerDisabled']
        },
        {
            path: '/user/password',
            name: 'menu.user.settings.password',
            exact: true,
            component: UserPassword,
            route: PrivateRoute,
            roles: ['Admin','User','Supplier','Worker','WorkerDisabled','Agency']
        },
        {
            path: '/user/beneficiaries',
            name: 'menu.user.settings.beneficiaries',
            exact: true,
            component: UserBeneficiaries,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/user/documents',
            name: 'menu.user.settings.documents',
            exact: true,
            component: UserDocuments,
            route: PrivateRoute,
            roles: ['Worker']
        },
        {
            path: '/company/users/:id',
            name: 'menu.company.main',
            component: UserList,
            route: PrivateRoute,
            roles: ['User','Admin']
        },
        {
            path: '/user/add',
            name: 'menu.user.add',
            component: UserManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/user/edit/:id',
            name: 'menu.user.edit',
            component: UserManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/worker/register/:action',
            name: 'Register welcome',
            component: RegisterWizard,
            route: PrivateRoute,
            roles: ['Worker'],
            isWorkerRegister: true
        },
        {
            path: '/worker/register',
            name: 'Register wizard',
            component: RegisterWizard,
            route: PrivateRoute,
            roles: ['Worker'],
            isWorkerRegister: true
        },
        {
            path: '/normative/add',
            name: 'menu.normative.add',
            component: NormativeManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/normative/edit/:id',
            name: 'menu.normative.edit',
            component: NormativeManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/modality/add',
            name: 'menu.modality.add',
            component: ModalityManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/modality/edit/:id',
            name: 'menu.modality.edit',
            component: ModalityManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/faq/add',
            name: 'menu.faq.add',
            component: FaqManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/faq/edit/:id',
            name: 'menu.faq.edit',
            component: FaqManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/suppliers/kindergarten/add',
            exact: true,
            name: 'menu.modality.kindergarten.add',
            component: KindergartenManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/kindergarten/settings/:id',
            exact: true,
            name: 'menu.modality.kindergarten.settings',
            component: KindergartenSettings,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/kindergarten/edit/:id',
            exact: true,
            name: 'menu.modality.kindergarten.edit',
            component: KindergartenManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/trainingCenter/add',
            exact: true,
            name: 'menu.modality.trainingCenter.add',
            component: TrainingCenterManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/trainingCenter/settings/:id',
            exact: true,
            name: 'menu.modality.trainingCenter.settings',
            component: TrainingCenterSettings,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/trainingCenter/edit/:id',
            exact: true,
            name: 'menu.modality.trainingCenter.edit',
            component: TrainingCenterManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/add',
            exact: true,
            name: 'menu.modality.insurance.add',
            component: InsuranceManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/settings/:id',
            exact: true,
            name: 'menu.modality.insurance.settings',
            component: InsuranceSettings,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/edit/:id',
            exact: true,
            name: 'menu.modality.insurance.edit',
            component: InsuranceManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/plan/add',
            exact: true,
            name: 'menu.modality.insurance.plan.add',
            component: InsurancePlanManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/plan/edit/:id',
            exact: true,
            name: 'menu.modality.insurance.plan.edit',
            component: InsurancePlanManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/coverage/add/',
            exact: true,
            name: 'menu.modality.insurance.coverage.add',
            component: InsuranceCoverageManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/coverage/edit/:id',
            exact: true,
            name: 'menu.modality.insurance.coverage.edit',
            component: InsuranceCoverageManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/price/add/',
            exact: true,
            name: 'menu.modality.insurance.price.add',
            component: InsurancePriceManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/suppliers/insurance/price/edit/:id',
            exact: true,
            name: 'menu.modality.insurance.price.edit',
            component: InsurancePriceManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/auxiliary/modality/category/add',
            name: 'menu.auxiliary.modality.add',
            component: ModalityCategoryManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/auxiliary/modality/category/edit/:id',
            name: 'menu.auxiliary.modality.add',
            component: ModalityCategoryManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/auxiliary/document/category/add',
            name: 'menu.auxiliary.document.add',
            component: DocumentCategoryManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/auxiliary/document/category/edit/:id',
            name: 'menu.auxiliary.document.add',
            component: DocumentCategoryManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/auxiliary/faq/category/add',
            name: 'menu.auxiliary.faq.add',
            component: FaqCategoryManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/auxiliary/faq/category/edit/:id',
            name: 'menu.auxiliary.faq.add',
            component: FaqCategoryManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/user/selections/:id',
            name: 'menu.users.selections',
            component: UserSelections,
            roles: ['Admin'],
            route: PrivateRoute,
        },
        {
            path: '/admin/ess/providers/add',
            name: 'menu.provider.add',
            component: EssProviderManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/admin/ess/providers/edit/:id',
            name: 'menu.provider.edit',
            component: EssProviderManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/admin/ess/activities/add',
            name: 'menu.activity.add',
            component: EssActivityManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/admin/ess/activities/edit/:id',
            name: 'menu.activity.edit',
            component: EssActivityManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/admin/ess/activities/:id',
            name: 'menu.ess.activities.list',
            component: EssActivityList,
            roles: ['Admin'],
            route: PrivateRoute,
        },
        {
            path: '/admin/ess/prices/add',
            name: 'menu.price.add',
            component: EssPriceManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/admin/ess/prices/edit/:id',
            name: 'menu.price.edit',
            component: EssPriceManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/admin/ess/prices/:id',
            name: 'menu.ess.prices.list',
            component: EssPriceList,
            roles: ['Admin'],
            route: PrivateRoute,
        },
        {
            path: '/ess/activity/price/:id',
            name: 'menu.ess.service.price',
            component: EssPrice,
            roles: ['User','Worker'],
            route: PrivateRoute,
        },
        {
            path: '/ess/activity/:id',
            name: 'menu.ess.service.activity',
            component: EssActivity,
            roles: ['User','Worker'],
            route: PrivateRoute,
        },
        {
            path: '/ess/:id',
            name: 'menu.ess.service.list',
            component: EssList,
            roles: ['User','Worker'],
            route: PrivateRoute,
        },
        {
            path: '/agency/invoices/:id',
            name: 'app.agency.invoices.menu',
            roles: ['Agency'],
            component: AgencyInvoices,
            route: PrivateRoute,
        }, {
            path: '/agency/payrolls/:id',
            name: 'app.agency.payrolls.menu',
            component: AgencyPayrolls,
            roles: ['Agency'],
            route: PrivateRoute
        }


    ]
}

const appRoutes = [
    companyRoutes,
    supplierRoutes,
    invoicingRoutes,
    userRoutes,
    workerRoutes,
    documentRoutes,
    benefitRoutes,
    savingsRoutes,
    cardsRoutes,
    selectionsRoutes,
    demandRoutes,
    payrollRoutes,
    invoiceRoutes,
    supplierInvoiceRoutes,
    supplierProfileRoutes,
    agencyProfileRoutes,
    agencyInvoicesRoutes,
    agencyPayrollsRoutes,
    userConfigRoutes,
    simulateRoutes
];

// Auxiliary tables
const auxiliaryRoutes = {
    path: '/auxiliary',
    name: 'menu.auxiliary.main',
    icon: 't-icon t-icon-settings',
    header: 'menu.labels.settings',
    roles: [ 'Admin'],
    children: [
        {
            path: '/auxiliary/modality',
            name: 'menu.auxiliary.modality.main',
            component: ModalityCategoryList,
            route: PrivateRoute,
        },
        {
            path: '/auxiliary/document',
            name: 'menu.auxiliary.document.main',
            component: DocumentCategoryList,
            route: PrivateRoute,
        },
        {
            path: '/auxiliary/faq',
            name: 'menu.auxiliary.faq.main',
            component: FaqCategoryList,
            route: PrivateRoute,
        },
        {
            path: '/normative/list',
            name: 'menu.normative.main',
            component: NormativeList,
            route: PrivateRoute
        },
        {
            path: '/faq/list',
            name: 'menu.faq.main',
            component: FaqList,
            route: PrivateRoute
        }

    ],
};

// Ess Services
const essRoutes = (process.env.REACT_APP_SERVICES === 'on') ? {
    path: '/ess',
    name: 'menu.ess.main',
    icon: 't-icon t-icon-ess',
    header: 'menu.labels.navigation',
    component: EssServices,
    roles: ['User','Worker'],
    route: PrivateRoute
} : {};

// Ess Admin
const essAdminRoutes = {
    path: '/admin/ess',
    name: 'menu.ess.main',
    icon: 't-icon t-icon-ess',
    header: 'menu.labels.settings',
    roles: ['Admin'],
    children: [
        {
            path: '/admin/ess/providers',
            name: 'menu.ess.providers.list',
            component: EssProviderList,
            roles: ['Admin'],
            route: PrivateRoute,
        },
        {
            path: '/admin/ess/activities',
            name: 'menu.ess.activities.list',
            component: EssActivityList,
            roles: ['Admin'],
            route: PrivateRoute,
        },
        {
            path: '/admin/ess/prices',
            name: 'menu.ess.prices.list',
            component: EssPriceList,
            roles: ['Admin'],
            route: PrivateRoute,
        }
    ],
};

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/balance-request',
            name: 'Balance Request',
            component: BalanceRequest,
            route: Route,
        },
        {
            path: '/account/reset/:token',
            name: 'Reset',
            component: Reset,
            route: Route,
        },
        {
            path: '/supplier/reset/:token',
            name: 'Supplier Reset',
            component: SupplierReset,
            route: Route,
        },
        {
            path: '/worker/reset/:token',
            name: 'Worker Reset',
            component: WorkerReset,
            route: Route,
        },
        {
            path: '/organization/reset/:token',
            name: 'Organization Reset',
            component: OrganizationReset,
            route: Route,
        },
        {
            path: '/account/sso/:token',
            name: 'LoginSso',
            component: LoginSso,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes, authUnexposedRoutes, auxiliaryRoutes, essAdminRoutes, essRoutes];

const authProtectedRoutes = [dashboardRoutes, ...appRoutes, auxiliaryRoutes, essAdminRoutes, essRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
